<div class="container">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-12 col-sm-12 text-center">
                    <img src="assets/main-brand.png" alt="Logo1" width="250px" class="mt-5 mb-4">
                </div>
                <!--<div class="col-12 col-sm-12 col-md-6 text-end d-none d-sm-block">
                    <img src="assets/main-brand2.jpeg" alt="Logo2" width="100px">
                </div>-->
            </div>
        </div>
        <div class="row card-login">
            <!-- <div class="col-12 text-center mb-4">
                <h3>{{title}}</h3>
                <i class="fas fa-desktop fa-4x mb-2"></i>
                <h3><small class="text-muted">Iniciar Sesión</small></h3>
            </div> -->
            <fieldset>
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                    <div class="row jumbotron">
                        <div class="mb-3">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" placeholder="name@example.com"
                                    formControlName="email">
                                <label for="floatingInput">Correo electrónico</label>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" placeholder="name@example.com"
                                    formControlName="password">
                                <label for="floatingInput">Contraseña</label>
                            </div>
                        </div>
                        <button class="btn btn-light mb-2">Iniciar Sesión</button>
                    </div>
                </form>
                <div class="row jumbotron">
                    <button class="btn btn-danger" (click)="createTicket()">Crear Ticket</button>
                </div>

            </fieldset>
            <div class="row">
                <div class="col text-center">
                    <!-- <a class="forgot-password">¿Olvidaste la contraseña?</a> -->
                </div>
            </div>
        </div>
    </div>
</div>