import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceObject } from '../models/service-object';
import { NotificationService } from '../services/notification.service';
import { WebAPIService } from '../services/web-api.service';

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss']
})
export class TicketFormComponent implements OnInit {

  form!: FormGroup;
  callUserOption: boolean | undefined;
  priority: any = [];
  groups: any = [];
  @Input() free: any;
  @ViewChild("fileUpload", { static: false }) fileUpload!: ElementRef; files = [];
  accept: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private webAPI: WebAPIService,
    private toast: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required])],
      group_id: [""],
      ticket_priority_id: [""],
      ticket_names_id: ["", Validators.compose([Validators.required])],
      names: ["", Validators.compose([Validators.required])],
      description: ["", Validators.compose([Validators.required])],
      path_media: [""],
      check_call: [""],
      phone: ["", Validators.compose([Validators.required])],
      datetime_call: [""],
      institution: [""]
    });

    if (!this.free) {
      var servObj = new ServiceObject("priority");
      this.webAPI.GetAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.priority = x.data.priority;
        }).catch(x => { throw x.message; });

      var servObj = new ServiceObject("groups");
      this.webAPI.GetAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.groups = x.data.groups;
        }).catch(x => { throw x.message; });
    }

  }

  Save() {
    if (this.form.invalid) {
      this.toast.showWarning("Todos los campos son obligatorios*", "");
    } else {
      const fileUpload = this.fileUpload.nativeElement;
      var formData = new FormData();
      var data = this.form.controls;
      formData.append('ticket_origin_id', "3");
      formData.append('ticket_type_id', "1");
      formData.append('email', data.email.value);
      formData.append('group_id', this.free ? 5 : data.group_id.value);
      formData.append('ticket_priority_id', this.free ? 2 : data.ticket_priority_id.value);
      formData.append('ticket_names_id', data.ticket_names_id.value);
      formData.append('names', data.names.value);
      formData.append('description', data.description.value);
      formData.append('phone', data.phone.value);
      formData.append('datetime_call', data.datetime_call.value);
      formData.append('institution', data.institution.value);
      if (fileUpload.files.length) {
        const file = fileUpload.files[0];
        formData.append('path_media', file);
      }
      var servObj = new ServiceObject(this.free ? "ticket_free" : "ticket");
      servObj.data = formData;
      this.webAPI.PostAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.toast.showSuccess(servObj.message, "");
          this.activeModal.close();
        }).catch(x => { throw x.message; });
    }
  }

  acceptOpt() {
    this.accept = true;
  }

}
