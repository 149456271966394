<div class="modal-header">
    <h4 class="modal-title">Crear Ticket</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<!--<div class="modal-body" *ngIf="!accept">
    <h3>Estimado discente de acuerdo con la finalización del proceso de inscripción al “IX Curso de Formación Judicial
        Inicial para Jueces y Magistrados de la República”, nos permitimos indicar que no se procesarán solicitudes que no
        versen sobre dicha etapa del proceso que actualmente se encuentra culminada.</h3>
</div>-->
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="Save()">
        <div class="section-ticket">
            <div class="text-center mb-2">
                <small>Información del peticionario</small>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Correo</label>
                        <input type="email" class="form-control" formControlName="email">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Nombres</label>
                        <input type="text" class="form-control" formControlName="names">
                    </div>
                </div>
                <!-- <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">Nombre de la Institución Educativa</label>
                        <input type="text" class="form-control" formControlName="institution">
                    </div>
                </div> -->
                <!-- <div class="col-12">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="check_call"
                                (change)="callUserOption = callUserOption ? false : true">
                            <label class="form-check-label">
                                ¿Desea ser contactado?
                            </label>
                        </div>
                    </div>
                </div> -->
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Celular</label>
                        <input type="text" class="form-control" formControlName="phone" min="3000000000"
                            max="3999999999" step="1">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-8" *ngIf="callUserOption">
                    <div class="mb-3">
                        <label class="form-label">Fecha y hora</label>
                        <input type="datetime-local" class="form-control" formControlName="datetime_call">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-ticket">
            <div class="text-center mb-2">
                <small>Información del ticket</small>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <!-- <label class="form-label">Asunto</label>
                        <input type="text" class="form-control" formControlName="message"> -->
                        <select class="form-select" formControlName="ticket_names_id">
                            <option [value]="1">EQUIPO DE COMPUTO WORKSTATION</option>
                            <option [value]="2">EQUIPO DE COMPUTO PC</option>
                            <option [value]="3">IMPRESORA A B/N</option>
                            <option [value]="4">IMPRESORA A COLOR</option>
                            <option [value]="5">IMPRESORA A COLOR TABLOIDE</option>
                            <option [value]="6">IMPRESORA A B/N TABLOIDE</option>
                            <option [value]="7">ESCANER</option>
                            <!-- <option [value]="9">VISITA CISP</option> -->
                            <option [value]="8">OTROS</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">Descripción (incluya la ubicación)</label>
                        <textarea class="form-control" rows="4" formControlName="description"></textarea>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <label class="mb-1">Anexar evidencia (opcional)</label>
                    <input type="file" formControlName="path_media" #fileUpload id="fileUpload" name="fileUpload">
                </div>
            </div>
        </div>
        <div class="section-ticket" *ngIf="!free">
            <div class="text-center mb-2">
                <small>Información destino</small>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Prioridad</label>
                        <select class="form-select" formControlName="ticket_priority_id">
                            <option *ngFor="let item of priority" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Grupo destino</label>
                        <select class="form-select" formControlName="group_id">
                            <option *ngFor="let item of groups" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-grid gap-2">
                    <button class="btn btn-danger" type="submit">Guardar</button>
                </div>
            </div>
        </div>
    </form>
</div>