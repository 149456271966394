<div class="modal-header">
    <h4 class="modal-title" *ngIf="!emailValid && free"> Verificación del correo</h4>
    <h4 class="modal-title" *ngIf="!onlyRead && emailValid || !free">Ticket #{{item.id}} <span class="badge"
            [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">{{item.statuses.length
            > 0 ? item.statuses[item.statuses.length-1].name : 'Sin Revisar'}}</span></h4>
    <h4 class="modal-title" *ngIf="onlyRead">Pregunta frecuente - {{item.id}} </h4>
    <button *ngIf="!free" type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="!emailValid && free">
    <div class="row">
        <div class="col-12">
            <label class="form-label">Correo:</label>
            <input type="email" class="form-control" [(ngModel)]="email">
        </div>
    </div>
    <br />
    <div class="d-flex justify-content-end">
        <button class="btn btn-danger" style="background-color: green;" (click)="validateEmail()">Verificar</button>
    </div>
</div>
<div class="modal-body" *ngIf="emailValid || !free">
    <div id="info-ticket">
        <small class="text-muted" *ngIf="!onlyRead">Información del Ticket</small>
        <small class="text-muted" *ngIf="onlyRead">Información de la pregunta frecuente</small>
        <div class="card d-flex"
            [ngClass]="'border-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">
            <div class="card-body">
                <!-- info ticket -->
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-2 justify-content-center align-self-center text-center">
                        <button *ngIf="!onlyRead" type="button" class="btn btn-lg"
                            [ngClass]="'btn-outline-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">{{
                            item.names | shortName }}</button>
                        <button *ngIf="onlyRead" type="button" class="btn btn-lg"
                            [ngClass]="'btn-outline-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">PF-{{item.id}}</button>
                    </div>
                    <div class="col-12 col-sm-9 col-md-7">
                        <h3>{{item.message}}</h3>
                        <h6>
                            Descripción:
                            <small class="text-muted">{{ item.description}}</small>
                        </h6>
                        <h6>
                            Nombres:
                            <small class="text-muted">{{ item.names}}</small>
                        </h6>
                        <h6>
                            Institución Educativa:
                            <small class="text-muted">{{ item.institution}}</small>
                        </h6>
                        <h6>
                            Correo:
                            <small class="text-muted">{{ item.email}}</small>
                        </h6>
                        <h6>
                            Fecha:
                            <!--<small class="text-muted">{{ item.created_at | diffDays: currentDate }}</small>-->
                            <small class="text-muted">{{ item.created_at | date:'dd-MM-yyyy' }}</small>
                        </h6>
                        <h6 *ngIf="item.phone">
                            Celular:
                            <small class="text-muted">{{ item.phone }}</small>
                        </h6>
                        <h6 *ngIf="item.datetime_call">
                            Fecha y hora para llamar:
                            <small class="text-muted">{{ item.datetime_call | date: 'dd/MM/yyyy h:mm a' }}</small>
                        </h6>
                        <h6 *ngIf="item.user_role_group">
                            Creado por:
                            <small class="text-muted">{{ item.user_role_group.user_role.user.email }}</small>
                        </h6>
                    </div>
                    <div class="col-12 col-sm-3 mt-1">
                        <span class="mt-1"><i class="fas fa-bell"></i> Prioridad:
                            {{item.ticket_priority.name}}</span><br>
                        <span class="mt-1"><i class="fas fa-folder"></i> Grupo: {{item.group.name}}</span><br>
                        <span class="mt-1"><i class="fas fa-thermometer-three-quarters"></i>
                            Origen: {{item.ticket_origin.name}}</span>
                        <h6 *ngIf="item.path_media.length > 0">
                            <a [href]="storage+item.path_media" target="_blank" rel="noopener noreferrer">
                                <span class="badge" [ngClass]="'bg-danger'">Ver Adjunto</span>
                            </a>
                        </h6>
                    </div>
                </div>
                <!-- end info ticket -->
            </div>
        </div>
    </div>
    <div id="assign" *ngIf="!onlyRead && !free">
        <small class="text-muted">Asignado a</small>
        <h4><span (click)="deleteAssignUser(itemB.pivot.id)" class="badge rounded-pill"
                [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')"
                *ngFor="let itemB of item.user_role_groups"><i class="fas fa-times"
                    *ngIf="authS.user.roles[0].pivot.role_id != 3"></i>
                {{itemB.user_role.user.firstname}}
                {{itemB.user_role.user.lastname}}</span>
            <span *ngIf="!free && role_group != 4" (click)="toogleAssign()"
                class="badge rounded-pill"
                [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">
                <i class="fas fa-plus"></i>
            </span>
        </h4>
        <h4 *ngIf="item.user_role_groups.length==0"><span class="badge rounded-pill"
                [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">Sin
                Asignaciones</span>
        </h4>
    </div>
    <div id="assing-add" *ngIf="assing_add">
        <div class="row">
            <div class="col-12">
                <h5>Usuarios del grupo {{item.group.name}}</h5>
            </div>
            <div class="col">
                <select class="form-select" [(ngModel)]="selectedOption">
                    <option *ngFor="let user of users" [value]="user.groups[0].pivot.id">{{user.user.firstname}}
                        {{user.user.lastname}} - {{user.groups[0].name}}
                    </option>
                </select>
            </div>
            <div class="col">
                <h4>
                    <span class="badge rounded-pill" (click)="assignUser()"
                        [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">
                        Agregar
                    </span>
                    <span (click)="toogleAssign()" class="badge rounded-pill"
                        [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">
                        Cancelar
                    </span>
                </h4>
            </div>
        </div>
    </div>
    <div id="answers">
        <small class="text-muted" *ngIf="!onlyRead">Trazabilidad por orden cronologico</small>
        <small class="text-muted" *ngIf="onlyRead">Descripción de la solución</small>
        <div class="card d-flex mt-2 mb-2" *ngFor="let itemC of item.ticket_urg_status"
            [ngClass]="'border-' + itemC.status.css_class">
            <div class="card-body">
                <!-- info ticket -->
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-2 justify-content-center align-self-center text-center">
                        <button type="button" class="btn btn-lg" [ngClass]="'btn-outline-' + itemC.status.css_class">{{
                            itemC.user_role_group_ticket.user_role_group.user_role.user.firstname | shortName
                            }}</button>
                    </div>
                    <div class="col-12 col-sm-9 col-md-10">
                        <h3 *ngIf="!onlyRead">
                            <span class="mt-1"><i class="fas fa-thermometer-three-quarters"></i>
                                {{itemC.status.name}}</span>
                        </h3>
                        <h6>
                            Descripción:
                            <small class="text-muted">{{ itemC.description}}</small>
                        </h6>
                        <!--<h6>
                            Nombres:
                            <small class="text-muted">{{
                                itemC.user_role_group_ticket.user_role_group.user_role.user.firstname}}
                                {{itemC.user_role_group_ticket.user_role_group.user_role.user.lastname}}</small>
                        </h6>-->
                        <!--<h6>
                            Correo:
                            <small class="text-muted">{{
                                itemC.user_role_group_ticket.user_role_group.user_role.user.email}}</small>
                        </h6>-->
                        <h6>
                            Fecha:
                            <small class="text-muted">{{ itemC.created_at | date:'dd-MM-yyyy'  }}</small>
                        </h6>
                        <!--<h6>
                            Convenio:
                            <small class="text-muted">{{ itemC.phase }}</small>
                        </h6>-->
                        <h6 *ngIf="itemC.path_media && itemC.path_media.length > 0">
                            <a [href]="storage+itemC.path_media" target="_blank" rel="noopener noreferrer">
                                <span class="badge" [ngClass]="'bg-danger'">Ver Adjunto</span>
                            </a>
                        </h6>
                    </div>
                </div>
                <!-- end info ticket -->
            </div>
        </div>
        <h4 *ngIf="item.ticket_urg_status.length==0"><span class="badge rounded-pill"
                [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">Sin
                Trazabilidad</span>
        </h4>
        <div class="row text-end" *ngIf="!onlyRead">
            <div class="col-12">
                <h4>
                    <span *ngIf="permissionTicket" (click)="toogleStatus()" class="badge rounded-pill"
                        [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">
                        Agregar Estado
                    </span>
                </h4>
            </div>
        </div>
        <div class="row" *ngIf="status_add">
            <div class="col-12">
                <h5>Actualizar el estado del ticket</h5>
            </div>
            <form [formGroup]="form">
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6">
                            <label>Seleccione el nuevo estado</label>
                            <select class="form-select" [(ngModel)]="selectedStatus" formControlName="status_id">
                                <option *ngFor="let item of status" [value]="item.id">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6" *ngIf="selectedStatus==4">
                            <label>Seleccione el nuevo grupo</label>
                            <select class="form-select" [(ngModel)]="selectedGroup" formControlName="group_id">
                                <option *ngFor="let item of groups" [value]="item.id">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">
                            <label class="mb-1">Anexar evidencia (opcional)</label>
                            <input type="file" formControlName="path_media" #fileUpload id="fileUpload"
                                name="fileUpload">
                        </div>
                        <div class="col-12 mt-2">
                            <label>Ingrese una descripción</label>
                            <textarea class="form-control" rows="3" formControlName="description"></textarea>
                        </div>
                        <!--<div class="col-12" >
                            <label>Seleccione el convenio</label>
                            <select class="form-select" [(ngModel)]="selectedGroup" formControlName="phase">
                                <option value="Convenio 1">Convenio 1</option>
                                <option value="Convenio 2">Convenio 2</option>
                                <option value="Convenio 3">Convenio 3</option>
                                <option value="Convenio 4">Convenio 4</option>
                                <option value="Convenio 5">Convenio 5</option>
                            </select>
                        </div>-->
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <h4>
                        <span class="badge rounded-pill" (click)="assignStatus()"
                            [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">
                            Guardar
                        </span>
                        <span (click)="toogleStatus()" class="badge rounded-pill"
                            [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'danger')">
                            Cancelar
                        </span>
                    </h4>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="!free">
    <button type="button" class="btn btn-outline-dark" (click)="closeModal()">Cerrar</button>
</div>