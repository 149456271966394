<nav class="navbar navbar-light {{authS.user ? 'show' : 'hide'}}">
    <!-- <nav class="navbar navbar-light "> -->
    <div class="container-fluid">
        <!-- <img class="navbar-brand" src="assets/logos.png" alt="Logo1" width="250px"> -->
        <img src="assets/main-brand.png" alt="Logo1" width="250px" class="mt-3 mb-3">
        <!-- <i class="fas fa-2x" [ngClass]="title.icon"></i> -->
        <a class="navbar-brand" href="#" style="color: white;">{{title.name}}</a>
        <!-- <img class="navbar-brand" src="assets/LOGOS-06.png" alt="Logo1" width="180px"> -->
        <button type="button" id="sidebarCollapse" class="btn btn-warning mb-1">
            <i class="fas fa-align-right"></i>
        </button>
    </div>
</nav>
<div class="overlay"></div>